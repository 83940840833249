import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "insertAnchor", "template" ]

  initialize() {
    const indexStub = this.data.get("indexStub") || "NEW_NESTED_FORM_INDEX"
    this.indexStubRe = new RegExp(indexStub, "g")
  }

  addAssociation(event) {
    event.preventDefault()
    this.insert("beforebegin")
  }

  append(e) {
    e.preventDefault()
    this.insert("beforeend")
  }

  insert(where) {
    const content = this.templateTarget.innerHTML.replace(this.indexStubRe, Date.now())
    this.insertAnchorTarget.insertAdjacentHTML(where, content)
  }

  removeAssociation(event) {
    event.preventDefault()

    const confirmMessage = event.target.dataset.actionConfirm
    if (confirmMessage && !window.confirm(confirmMessage)) {
      event.stopPropagation()
      return
    }

    const wrapper = event.target.closest(".nested-fields")
    if (wrapper) {
      this.#removeByWrapper(wrapper)
    } else {
      this.#removeByInsertAnchorParent(event.target)
    }
  }

  #removeByWrapper(wrapper) {
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  #removeByInsertAnchorParent(eventTarget) {
    this.items.forEach(el => {
      if (el.contains(eventTarget)) {
        el.remove()
      }
    })
  }

  get items() {
    return [ ...this.insertAnchorTarget.parentNode.children ]
  }
}
