function closeDropdowns(target) {
  document.querySelectorAll('.dropdown').forEach(el => {
    if (el.contains(target)) { return }
    el.classList.remove('is-active')
  })
}

function openDropdown(e) {
  e.stopPropagation()
  const dropdown = e.target.closest('.dropdown:not(.is-hoverable)')
  dropdown && dropdown.classList.toggle('is-active')
}

document.addEventListener('click', e => {
  closeDropdowns(e.target)
})

document.addEventListener('click', e => {
  if (e.target.closest('.dropdown-trigger')) {
    openDropdown(e)
  }
})
