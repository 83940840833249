import { Controller } from "stimulus"
import { once } from "../utils"

function zeroPad(num) {
  return num < 10 ? "0" + num : num
}

function currentTimestamp() {
  return Math.round(Date.now() / 1000)
}

export default class extends Controller {
  static targets = [ "watch" ]
  static values = { seconds: Number, syncSelector: String }
  static classes = [ "paused", "resumeAnimation" ]

  initialize() {
    this._paused = false
    this.seconds = this.secondsValue || 0
  }

  connect() {
    if (!this._paused) this.resume()

    window.addEventListener("blur", this.recoverTimerFromBackground)
  }

  disconnect() {
    window.removeEventListener("blur", this.recoverTimerFromBackground)
  }

  resume = () => {
    if (this._interval) return

    this._interval = setInterval(this.tick, 1000)
    this.element.classList.remove(this.pausedClass)

    this._paused = false
  }

  resumeWithActivity = e => {
    if (!this._paused) return
    if (![ window, document ].includes(e.target)) return

    this.resume()
    this.element.classList.add(this.resumeAnimationClass)
    once(this.element, "animationend", () => {
      this.element.classList.remove(this.resumeAnimationClass)
    })
  }

  tick = () => {
    this.seconds += 1
  }

  pause = () => {
    this._paused = true
    this._pause()
    this._resumeOnActivity()
  }

  _pause() {
    clearInterval(this._interval)
    this._interval = null
    this.element.classList.add(this.pausedClass)
  }

  // only _after_ the windwo/tab lost focus we listen for the user returning
  // and resume the timer. this is to prevent resuming the timer
  // right after it was paused and allows the user to still interact with the page.
  _resumeOnActivity(onEvents = [ "focus", "scroll", "click", "keydown" ]) {
    once(window, "blur", () => {
      onEvents.forEach(e => {
        once(window, e, this.resumeWithActivity)
      })
    })
  }

  toggle = e => {
    this._paused ? this.resume(e) : this.pause(e)
  }

  updateWatch() {
    this.watchTarget.textContent = this.timeTuple.join(":")
  }

  syncSelectors() {
    document.querySelectorAll(this.syncSelectorValue).forEach(el => {
      el.value = this.seconds
    })
  }

  recoverTimerFromBackground = () => {
    if (this._paused) { return }

    this._secondsPassed = this.seconds
    this._blurredAt = currentTimestamp()

    once(window, "focus", () => {
      this.seconds = currentTimestamp() - this._blurredAt + this._secondsPassed
    })
  }

  set seconds(value) {
    this._seconds = parseInt(value, 10)
    this.updateWatch()
    this.syncSelectors()
  }

  get seconds() {
    return this._seconds
  }

  get timeTuple() {
    const h = parseInt(this.seconds / 3600, 10)
    let s = this.seconds - h * 3600
    const m = parseInt(s / 60, 10)
    s -= m * 60

    return [ h, m, s ].map(zeroPad)
  }
}
