import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "busy" ]

  initialize() {
    this._observer = new MutationObserver(this.handleMutations)
  }

  connect() {
    this._observer.observe(
      this.element,
      { subtree: false, attributes: true, attributeFilter: [ "busy" ] }
    )
  }

  disconnect() {
    this._observer.disconnect()
  }

  handleBusyChange(frameTarget) {
    if (frameTarget.hasAttribute("busy")) {
      frameTarget.innerHTML = null
      frameTarget.append(this._busyTarget)
    }
  }

  busyTargetConnected() {
    if (!this._busyTarget) {
      this._busyTarget = this.busyTarget.cloneNode(true)
    }
  }

  handleMutations = mutations => {
    mutations.forEach(m => {
      if (m.type == "attributes" && m.attributeName == "busy") {
        this.handleBusyChange(m.target)
      }
    })
  }
}
