import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  open() {
    this._open()
  }

  close(e) {
    e.preventDefault()
    this._close()
  }

  _open() {
    this.containerTarget.classList.add("visible")
    document.body.parentNode.style.overflowY = "hidden"
  }

  _close() {
    this.containerTarget.classList.remove("visible")
    document.body.parentNode.style.overflowY = null
  }
}
